@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #383636; /* Blue */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
    margin: auto;
  }
  
  .spinner-container {
    display: grid;
    justify-content: center;
    align-items: center;
    opacity:    0.5; 
    background: rgb(255, 255, 255); 
    width:      100%;
    height:     100%; 
    z-index:    10;
    top:        0; 
    left:       0; 
    position:   fixed; 
  }

  .small-loading-spinner {
    width: 27px;
    height: 27px;
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid #383636; /* Blue */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
    margin: auto;
  }
  
  
  .small-spinner-container {
    justify-content: center;
    align-items: center;
    opacity:    0.5; 
    width:      100%;
    height:     100%; 
  }