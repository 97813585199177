@import url('https://fonts.googleapis.com/css?family=Nunito');

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    font-family: 'Nunito', "Verdana", sans-serif;
    /* color: #191717; maybe this will break everything */
}

a {
    color: #ff3333 !important;
    text-decoration: none !important;
}

body {
    background-color: white;
    display: flex;
    justify-content: center;
    /*
    align-items: center;
    min-height: 100vh;
*/
}

.addButton {
    /* background-color: #77D32F; */
    background-color: #AAC9BA;
    color: white;
    text-align: left;
    /* margin: 0 auto; */
    padding-right: 22px;
    position: fixed;
    left: 50%;
    bottom: 5px;
    transform: translate(-20%, -50%);
    margin: 0 auto;
    border-radius: 100%;
    height: 70px;
    width: 70px;
}

.TransactionAmount {
    width: 100%;
    margin: 0;
    font-size: 58px;
    height: 180px;
    padding: 50px 45px 10px 10px;
    border: none;
    text-align: right;
}

.TransactionAmount:focus {
    outline: none;
}

.IncomeAmount {
    color: #569E1E;
    background-color: #ECFFDD;
}

.ExpenseAmount {
    color: #FA5835;
    background-color: #FFEEEB;
}

.TransferAmount {
    color: #BB7B00;
    background-color: #FFF5E1;
}

.desktopAmount {
    background-color: transparent !important;
}

#confirmedButtons {
    width: 80%;
    margin: auto;
}

.confirmedAnim {
    animation: hideAnimation 0s ease-in 1s;
    animation-fill-mode: forwards;
}

.confirmedAnim2 {
    animation: showAnimation 0s ease-in 1.75s;
    animation-fill-mode: forwards;
    visibility: hidden;
    animation-delay: 1s;
}

/* 
.stopAnim2 {
    animation-delay: 3.5s;
    animation: hideAnimation2 2s ease-in 1.75s;
} */


@keyframes hideAnimation {
    to {
        visibility: hidden;
        width: 0;
        height: 0;
    }
}

@keyframes showAnimation {
    to {
        visibility: visible;
    }
}

@keyframes hideAnimation2 {
    to {
        animation-play-state: paused;
    }
}


#transTypeGroup {
    width: 100%;
}

#transTypeGroup>.btn {
    width: 50%;
    height: 60px;
    line-height: 50px;
    font-size: 20px;
}

#desktopTransTypeGroup {
    margin-left: 50px;
    margin-top: 30px;
} 

#desktopTransTypeGroup>.btn {
    width: 40%;
    height: 60px;
    line-height: 50px;
    font-size: 20px;
    margin: auto;
    margin-left: 20px;
    border-radius: 8px;
}

.react-toggle-track,
.react-toggle-thumb {
    margin-top: 7px;
}

#transXFRToggleDiv>.react-toggle--checked .react-toggle-track {
    background-color: #F2AF2C !important;
}

#transXFRToggleDiv>.react-toggle--checked .react-toggle-thumb {
    border-color: #F2AF2C !important;
}

/* .react-toggle-track {
    height: 30px !important;
    width: 100px !important;
} 
.react-toggle-thumb {
    height: 25px !important;
    width: 25px !important;
    margin-top: 1px !important;
} */

#newTransCat {
    width: 100% !important;
}

.transType {
    margin-top: 40px;
    text-align: center;
}

.transTypeLabel {
    margin-top: 5px;
    font-size: 20px;
}

.transPayee {
    margin-top: 40px;
}

.transInput {
    background-color: #F6F6F6;
    border: none;
    font-size: 20px;
    border: 2px solid transparent;
    box-sizing: border-box;
}

.desktopTransInput {
    background-color: #EFF0F0;
    border: none;
    font-size: 20px;
    border-radius: 50px;
    padding-left: 20px;
    /* padding-right: 20px; */
    border: 2px solid transparent;
    box-sizing: border-box;
}


.splitAmount {
    background-color: #F6F6F6;
    border: none;
    font-size: 20px;
    width: 100%;
}

.saveOrCancelButton {
    font-size: 24px;
    padding: 20px 0px;
    color: white;

}

.saveButtonRed {
    background-color: #FA5835;
}

.saveButtonGreen {
    background-color: #77D32F;
}

.saveButtonYellow {
    background-color: #F2AF2C;
}

.cancelButton {
    background-color: #F2F2F2;
    color: #B2B2B2;
}


.navbar {
    border-bottom: solid 1px #EBEBEB;
    /* background-color: #F2F2F2; */
}

.bottom-tab-nav {
    border-top: solid 1px #EBEBEB;
}

.nav-link,
.bottom-nav-link {
    color: #B2B2B2 !important;
}

.bottom-nav-link.active {
    color: #575757 !important;
}

.bottom-tab-label {
    font-size: 12px;
}

.transCard {
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: 0.5rem;
    padding-top: 0px;
    padding-bottom: 20px;
    /* width: 100%; */
    padding-right: var(--bs-gutter-x, 1.25rem);
    padding-left: var(--bs-gutter-x, 0.15rem);
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 20px;

    background-color: white;
    border: 1px solid white;
    margin: 10px;
}

.transCard:hover {
    cursor: pointer;
    box-shadow: -6px 6px 2px 1px rgba(0, 0, 255, .1);
}

.desktopTransCard {
    margin-bottom: 30px;
    margin-left: 70px;

}

.monthSingleContainer {
    width: fit-content;
    margin: 10px;
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 40px;
}

.monthSingleName {
    color: rgba(58, 58, 58, 0.5);
    width: fit-content;
    font-weight: bold;
    padding: 5px 15px;
}

.monthSingleYear {
    font-size: 14px;
    color: rgba(58, 58, 58, 0.2);
    padding-bottom: 5px;
}


#activeMonth {
    background-color: #F2F2F2;
    color: #3A3A3A;
    border-radius: 8px;
}


.active .monthSingleYear,
.active .monthSingleName {
    color: #3A3A3A;
}


.accountContainer {
    width: 105px;
    margin-right: 30px;
}


.desktopAccountContainer {
    width: 155px;
    margin-right: 30px;
}


.accountSingleContainer {
    width: fit-content;
    height: 125px;
    width: 105px;
    border-radius: 8px;
    background-color: #7EC9C4;
    color: white;
    opacity: 0.3;
    padding: 12px;

    margin: 10px;
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 40px;
}


.accountSingleContainerSelect {
    width: fit-content;
    height: 125px;
    width: 105px;
    border-radius: 8px;
    background-color: #7EC9C4;
    color: white;
    opacity: 0.3;
    padding: 12px;

    margin: 10px;
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 40px;

    width: 77px;
    margin-right: 30px;
    color: black;
    border: 3px dashed #7EC9C4;
    background-color: transparent;
}

.desktopAccountSingleContainer {
    width: fit-content;
    height: 105px;
    width: 160px;
    border-radius: 8px;
    background-color: #7EC9C4;
    color: white;
    opacity: 0.3;
    padding: 12px;

    margin: 10px;
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 40px;

}


.desktopAccountSingleContainerSelect {
    height: 105px;
    border-radius: 8px;
    opacity: 0.5;
    padding: 12px;

    margin: 10px;
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 40px;

    width: 77px;
    margin-right: 30px;
    color: black;
    border: 3px dashed #7EC9C4;
    background-color: transparent;
}

.accountSingleName {
    width: fit-content;
    font-size: 12px;
}

.accountSingleBalance {
    margin-top: 35px;
    font-size: 14px;
}

.accountSingleStars {
    font-size: 12px;
}

#activeAccount {
    opacity: 1;
}



.desktopAccountNav>.react-horizontal-scrolling-menu--wrapper {
    width: 100%;
    max-width: 100% !important;
    height: 100%;
    overflow: hidden;
    /* overflow:scroll; */
    margin: auto;
}


.react-horizontal-scrolling-menu--wrapper {
    width: 100%;
    max-width: 350px;
    height: 100%;
    overflow: hidden;
    /* overflow:scroll; */
    margin: auto;
}

.desktopAccountNav > div > .react-horizontal-scrolling-menu--inner-wrapper {
    background-color: #ffffff45;
    border-radius: 10px;
}

.react-horizontal-scrolling-menu--scroll-container {
    width: 100%;
    height: 100%;
    /* overflow-y: scroll; */
    overflow-y: visible !important;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    padding-right: 17px;
    /* Increase/decrease this value for cross-browser compatibility */
    box-sizing: content-box;
    /* So the width will be 100% + 17px */
    /* overflow-y: scroll; */
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
}

/* SCROLL BAR STYLE */
/* width */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 5px grey;  */
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(197, 197, 197); 
    border-radius: 5px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #8b8b8b; 
  }





.desktopScrollNavMonths>.react-horizontal-scrolling-menu--scroll-container {
    width: 100%;
    height: 100%;
    /* overflow-y: scroll;
    -ms-overflow-style: none; */
    /* IE and Edge */
    /* scrollbar-width: none; */
    /* Firefox */
    padding-right: 17px;
    /* Increase/decrease this value for cross-browser compatibility */
    box-sizing: content-box;
    /* So the width will be 100% + 17px */
    /* overflow-y: scroll; */
    /* scrollbar-width: none; */
    /* Firefox */
    /* -ms-overflow-style: none; */
    /* Internet Explorer 10+ */
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
}

.progress-sm {
    height: 0.5rem !important;
    margin-top: 3px;
}

.progress.progress-container {
    background-color: transparent !important;
}

.catName {
    font-size: 16px;
    font-family: 'Nunito', cursive;
    font-weight: bold;
    color: #3A3A3A;
}

.catSpent {
    font-size: 14px;
    font-weight: bold;
    font-family: 'Roboto', cursive;
    color: #B2B2B2;
}

.desktopBudgetBal {
    /* float: ; */
}

.desktopBudgetArrow {
    /* width: 10px !important;
    float: right !important; */
}

.balVal {
    font-size: 20px;
    font-weight: bold;
    color: #3A3A3A;
}

.balType {
    font-size: 20px;
    font-weight: normal;
    color: #3A3A3A;
}

.budgetCat {
    margin: 20px 0px;
}

.budgetAlert {
    margin: 0px 20px;
}


.progress-bar.bg-success {
    /* background-color: #77D32F !important; */
    background-color: #AAC9BA !important;
}

.progress-bar.bg-danger {
    background-color: #FA5835 !important;
}

.progress-bar.bg-info {
    background-color: #F2F2F2 !important;
}

.form-control.budget {
    /* border: 1px solid red !important; */
    text-align: right !important;
    width: 105px !important;
}


.budgetAmt {
    background-color: #F2F2F2 !important;
    /* border: 2px solid #D8D8D8 !important; */
    border: none;
    color: #4A5568 !important;
    font-weight: bold !important;
    /* padding: 2px 5px; */
    padding-left: 10px;
    ;
    text-align: justify;
    font-size: 14px;
    border-radius: 8px;
    width: 85px;
    float: right;

}

.alert {
    border-radius: 20px !important;
    padding: 15px 25px 1px 25px !important;
}

.desktopAlertRounded {
    border-radius: 20px !important;
    padding: 15px 25px 1px 25px !important;
}


.desktopAlert {
    border-radius: 8px !important;
    padding: 15px 25px 1px 25px !important;
    width: 100%;
}

.alert-danger {
    color: #B12506 !important;
    /* background-color: #FFEEEB !important; */
    background-color: #FFD0C6 !important;
    border: none !important;
}

.alert-success {
    /* color: #569E1E !important;
    background-color: #ECFFDD !important; */
    color: #335C67 !important;
    background-color: #C9F2E8 !important;
    border: none !important;
    text-align: center !important;
}

.alert-warning {
    /* color: #569E1E !important;
    background-color: #ECFFDD !important; */
    color: #6A472D !important;
    background-color: #f8ebcd !important;
    border: none !important;
    text-align: center !important;
}

.alert-grey {
    color: white;
    background-color: #D9D9D9;
    border: none !important;
    text-align: center !important;
}

.alert-heading {
    font-weight: bold !important;
    font-size: 22px !important;
    margin-bottom: 15px !important;
}

.alert-heading-regular {
    font-size: 22px !important;
    margin-bottom: 15px !important;
}


.alert-subheading {
    font-weight: bold !important;
    font-size: 18px !important;
    margin-bottom: 15px !important;
}

.sprout-color {
    color: #a3c270
}

.sideways-label {
    writing-mode: vertical-rl;
    /*text-orientation: sideways;*/
    transform:scale(-1);
}

.app {
    background-color: white;
    width: 400px;
    min-height: 600px;
    max-height: fit-content;
    border-radius: 15px;
    padding: 20px;
    position: relative;
    margin-top: 40px;
    /*
    display: flex;
    justify-content: space-evenly;
*/
}

@media only screen and (max-width: 600px) {
    .app {
        width: 100%;
    }
    .react-horizontal-scrolling-menu--scroll-container {
        overflow-y: scroll !important;
    }
}

.backDiv {
    width: 100%;
    min-height: 50px;
}

.score-section {
    display: flex;
    font-size: 24px;
    align-items: center;
}

/* QUESTION/TIMER/LEFT SECTION */
.question-section {
    width: 100%;
    position: relative;
}

.question-count {
    margin-bottom: 20px;
}

.question-count span {
    font-size: 28px;
}

.question-text {
    margin-bottom: 12px;
}

.timer-text {
    background: rgb(230, 153, 12);
    padding: 15px;
    margin-top: 20px;
    margin-right: 20px;
    border: 5px solid rgb(255, 189, 67);
    border-radius: 15px;
    text-align: center;
}

/* ANSWERS/RIGHT SECTION */
.answer-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.onBButton {
    border: none;
    cursor: pointer;
    background-color: #F6F4EE !important;
    color: #191717 !important;
    text-decoration: none !important;
    border-radius: 8px !important;
    padding: 15px 25px !important;
    font-size: 18px !important;
    margin: 5px !important;
}

.correct {
    background-color: #2f922f;
}

.incorrect {
    background-color: #ff3333;
}

button:hover {
    background-color: #F96145 !important;
    /* background-color: #198754 !important; */
    color: white !important;
    font-weight: 500;
    ;
}

.btn-active {
    background-color: #F96145 !important;
    color: white !important;
    font-weight: 500;
}

.progress {
    background-color: #F6F4EE !important;
}

.progress-bar {
    background-color: #F96145 !important;
}

button:focus {
    outline: none;
}

button svg {
    margin-right: 5px;
}

.back-btn {
    padding: 5px 5px !important;
    font-size: 12px !important;
    min-height: 0px !important;
    margin: 5px !important;
}


.back-btn:hover {
    background-color: #F6F4EE !important;
    background-image: linear-gradient(rgba(0, 0, 0, 0.1) 0 0);
    color: #191717 !important;
    font-weight: 500;
}

.answerList {
    list-style-type: none;
    margin: 0 0 0 0;
    padding: 0;
}


.answerList li {
    display: block;
    width: 100%;
    position: relative;
    margin-top: -20px;
}

.answerList label {
    border: none;
    cursor: pointer;
    background-color: #F6F4EE;
    color: #191717;
    text-decoration: none;
    border-radius: 8px;
    padding: 15px 25px !important;
    font-size: 18px !important;
    min-height: 60px !important;
    margin: 5px !important;
    width: 100%;
}

.answerList input {
    border: none;
    cursor: pointer;
    background-color: #F6F4EE;
    color: #191717;
    text-decoration: none;
    border-radius: 8px;
    padding: 15px 25px !important;
    font-size: 18px !important;
    margin: 5px !important;
    width: 100%;
    height: 0px;
}

.answerList input[type="radio"] {
    opacity: 0.011;
    z-index: 100;
}

.answerList input[type="radio"]:checked+label {
    background: #F96145;
    color: white;
    font-weight: 500;
}

.answerList label {
    padding: 5px;
    text-align: center;
    border: none !important;
    border: 1px solid #CCC;
    cursor: pointer;
    z-index: 90;
}

.answerList label:hover {
    background: #DDD;
}

.disclaimer {
    font-size: 12px;
    font-weight: 100;
    color: #6c757d;
}

input[type="number"]::placeholder,
input[type="number"],
input[type="number"] {
    text-align: center;
}

input[type="number"] {
    -moz-appearance: textfield;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    margin-left: 30px;
}

#emailHelp svg {
    color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
    margin-left: 2px;
    margin-right: 2px;
}



/* HISTORY PAGE */

.expenseAmt {
    /* color: #DB320D;
    background-color: #FFEEEB; */
    color: #B12506;
    background-color: #F2F2F2;
    font-size: 14px;
    text-align: center;
    padding: 0px 11px;
    border-radius: 20px;
    margin-bottom: 7px;
}

.incomeAmt {
    /* color: #569E1E;
    background-color: #ECFFDD; */
    color: #335C67;
    background-color: #DCF5F6;
    font-size: 14px;
    text-align: center;
    padding: 0px 11px;
    border-radius: 20px;
    margin-bottom: 7px;
}

.transSrc {
    color: #3A3A3A;
    font-size: 16px;
}

.transCat {
    color: #6F6F6F;
    font-size: 16px;
    font-weight: lighter;
}

.transDate {
    color: #6F6F6F;
    font-size: 14px;
    font-weight: lighter;
}

.runningBalance {
    color: #6F6F6F;
    font-size: 12px;
    font-weight: lighter;
    text-align: right;
}

.applyBtn {
    border: none !important;
    background-color: #7EC9C4 !important;
    color: white !important;
}

.cancelBtn {
    background-color: #F6F6F6 !important;
}

.applyBtn:hover {
    background-color: #7EC9C4 !important;
}

.applyBtn,
.cancelBtn {
    padding: 0px 15px !important;
    line-height: normal !important;
    min-height: 40px !important;
    font-size: 16px !important;
}

.HistoryDateRangeInput {}

.daterangepicker .ranges li.active {
    background-color: #7EC9C4 !important;
    color: #fff;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
    background-color: #7EC9C4 !important;
}

.daterangepicker td.in-range {
    /* background-color: #f8eeeb !important; */
    background-color: #eefae3 !important;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
    /* background-color: #db3d0d !important; */
    background-color: #7EC9C4 !important;
}

.daterangepicker td.off,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date,
.daterangepicker td.off.end-date {
    background-color: #fff !important;
}

.daterangepicker .ranges li {
    font-size: 16px !important;
    padding: 8px 14px !important;
    cursor: pointer;
}

.form-control {
    text-align: center !important;
    /* width: 90% !important; */
    margin: auto !important;
    background-color: #F6F6F6 !important;
    border: none !important;
    /* margin-bottom: 40px !important; */
}

.btnAccSelect,
.dropdown-menu {
    font-size: 16px !important;
    /* margin-top: 70px !important; */
}

.btnAccSelect {
    background-color: #F6F6F6 !important;
    border: none !important;
    padding: 10px 20px !important;
    min-height: 40px !important;
    margin-top: 40px !important;
}

.btnAccSelect:hover {
    border: none !important;
}

.btnAccSelect:focus {
    outline: 0 !important;
}

#dropdown-basic.btnAccSelect:hover {
    background-color: #eee !important;
    font-weight: 500;
}


.dropdown-toggle::after {
    display: none !important;
}

#currAccLabel {
    color: #212529;
}

#currAccLabel::after {
    margin-top: 10px !important;
    border: 1px solid red !important;
}

.transList {
    margin-bottom: 150px;
}


.dropdown-item.active,
.dropdown-item:active {
    background-color: #76d22e !important;
}

.btn-check:focus+.btn-primary,
.btn-primary:focus,
.btn-check:focus+.btn-primary,
.form-control:focus {
    box-shadow: none !important;
}


.accRow {
    padding: 20px 10px;
}

.accRow:hover {
    background-color: #eee !important;
    cursor: pointer;
}

.halfWidth {
    width: 50% !important;
    margin: 0px !important;
    border-radius: 0 !important;
    border: none !important;
    min-width: 200px !important;
}

.saveBtn {
    background-color: #77D32F !important;
}

.saveBtn:hover {
    background-color: #569E1E !important;
}

.discardBtn {
    background-color: #F2F2F2 !important;
    color: #B2B2B2 !important;
}



.accInfo {
    color: #6F6F6F;
}

.budgetBal-pos {
    /* color: #569E1E;
    background-color: #ECFFDD; */
    color: #335C67;
    background-color: #DCF5F6;
    text-align: center;
    padding: 0px 11px;
    border-radius: 20px;
    margin-bottom: 7px;
    width: fit-content;
    float: right;
    font-size: 14px;
    font-weight: bold;
    font-family: 'Nunito';
}

.budgetBal-neg {
    /* color: #DB320D;
    background-color: #FFEEEB; */
    color: #B12506;
    background-color: #F2F2F2;
    text-align: center;
    padding: 0px 11px;
    border-radius: 20px;
    margin-bottom: 7px;
    width: fit-content;
    float: right;
    font-size: 14px;
    font-weight: bold;
    font-family: 'Nunito';
}

.budgetBal-neu {
    /* color: #7d7b7b;
    background-color: #f8f3f3; */
    color: #7d7b7b;
    background-color: #f8f3f3;
    text-align: center;
    padding: 0px 11px;
    border-radius: 20px;
    margin-bottom: 7px;
    width: fit-content;
    float: right;
    font-size: 14px;
    font-weight: bold;
    font-family: 'Nunito';
}


.budgetBal-warn {
    /* color: #569E1E;
    background-color: #ECFFDD; */
    color: #6A472D;
    background-color: #FFF5E1;
    text-align: center;
    padding: 0px 11px;
    border-radius: 20px;
    margin-bottom: 7px;
    width: fit-content;
    float: right;
    font-size: 14px;
    font-weight: bold;
    font-family: 'Nunito';
}

#waitlist_title {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, hsl(51, 100%, 84%) 50%);
    display: inline;
    font-weight: 700 !important;
}

#waitlist_title_containter {
    margin-top: 100px !important;
}

.waitlist_number {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, hsl(51, 100%, 84%) 50%);
    display: inline;
    font-weight: 700 !important;
    font-size: x-large;
}



#waitlist_input,
#referral_input {
    border: solid palegoldenrod 2px;
    border-radius: 290486px;
    padding: 5px;
    padding-left: 15px;
    padding-bottom: 10px;
    width: 70% !important;
    max-width: 600px !important;
    height: fit-content;
}

#email_input,
#referral_link {
    margin-top: 5px;
    margin-left: 10px;
    width: 70% !important;
    /* max-width: 700px !important; */
    border: none;
}

#email_input:active,
#referral_link:active,
#email_input:focus,
#referral_link:focus,
#email_input:focus-visible,
#referral_link:focus-visible {
    border: none !important;
    outline: none !important;
}

#referral_input {
    border: solid hwb(158deg 57% 0%) 2px;
}

#btn_submit {
    line-height: normal !important;
    height: 36px !important;
    border-radius: 290486px;
    float: right !important;
}

#btn_confirmed {
    height: 36px !important;
    width: 36px !important;
    border-radius: 290486px;
    float: right !important;
    font-weight: 700 !important;
}

@media screen and (max-width: 995px) {
    #email_input {
        width: 60% !important;
    }

}

#refSocials {}

.img_social {
    width: 40px;
}

#tiktok_social {
    background-color: black;
    border-radius: 6px;
}

.confirmToggleLabel {
    padding-top: 7px;
    text-align: left;
    padding-left: 35px;
}




.rstra-container {
    overflow: visible !important;
    height: fit-content;
}

.rstra-container>div {
    overflow: visible !important;
    height: fit-content;
}

.budgetcatdiv {
    /* border: 1px solid red !important; */
    height: fit-content;
    margin-bottom: 10px;
    border-radius: 8px;
    padding: 20px 10px;
    /* padding-bottom: 52px; */
    background-color: white;
    /* padding top/bottom right/left */
}

.bcatContainer {
    width: 100%;
    margin: auto !important;

}

.rstra-dots-button {
    display: none !important;
}

.rstra-container {
    padding: 0px !important;
    /* height: fit-content !important; */
    margin-bottom: -17px !important;
    /* border: 1px dotted blue !important; */
}

.rstra-actions-container {
    margin-top: -17px;
}

.rstra-content-container {
    padding: 0px !important;
    height: fit-content !important;
    margin-top: -17px;
}

.greyBG {
    background-color: #f2f2f2;
    min-height: 1000px;
    overflow: hidden;
}

body {
    display: block !important;
}

.budgetsummary {
    margin-top: 50px;
    margin-bottom: 20px;
    border-radius: 8px;
}

.budgetSumAmt {
    font-size: 12px;
    font-weight: 800;
    font-family: 'Nunito', "Verdana", sans-serif;
    color: #3A3A3A;
}

.budgetSumAmt-1,
.budgetSumAmt-2 {
    border-right: 1px solid #B2B2B2;
}

#saveRateBubble {
    background-color: #C9F2E8;
    border-radius: 20px;
    border: 4px solid white;
    padding-bottom: 15px;
    padding-top: 5px;
    width: 100%;
    margin-top: -30px;
    margin-bottom: 20px;

}

#saveRatePerc {
    font-size: 32px;
    font-weight: 800;
    font-family: 'Nunito', "Verdana", sans-serif;
    color: #335C67;
}


#saveRateLabel {
    font-size: 16px;
    font-weight: 600;
    font-family: 'Nunito', "Verdana", sans-serif;
    color: #335C67;
    opacity: .8;
    margin: -10px;
}

.colorDot {
    font-size: 8px;
    margin-bottom: 1px;
    margin-top: 5px;
    margin-right: 12px;
    float: left;

}

.colorDotIncome {
    color: #90A3C3;
}

.colorDotExpense {
    color: #FDA78C;
}

.colorDotPretax {
    color: #FFBB37;
}

.colorDotPosttax {
    color: #7EC9C4;
}

.colorDotClear {
    color: transparent;
}

.summaryLabel {
    font-weight: 100;
    float: left;
}

.summaryValue {
    float: right;
}

.summaryLabel .summaryValue {
    display: inline;
}

#bottomSpacing {
    height: 75px;
}

.scrollNavMonths {
    border-bottom-left-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
}

.desktopScrollNavMonths {
    border-radius: 20px;
    background-color: #E4E4E4;
    width: 100%;
}

/* need identifier here */
.desktopScrollNavMonths>.react-horizontal-scrolling-menu--wrapper {
    max-width: 80% !important;
}

.css-box-shadow {
    background: #F8F8F8;
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 2px 7px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 2px 7px rgba(0, 0, 0, 0.1);
}

#currentDay {
    font-size: 24px;
    color: #3A3A3A;
    font-weight: 900;
    font-family: 'Nunito', "Verdana", sans-serif;
}


#currentDate {
    font-size: 13px;
    color: #3A3A3A;
    font-weight: 900;
    font-family: 'Nunito', "Verdana", sans-serif;
}

.button {
    font-size: 14px;
    padding: 15px 20px;
    width: fit-content;
    border-radius: 8px;
    font-family: 'Nunito', "Verdana", sans-serif;
    cursor: pointer;
    display: inline;
    margin: auto;
}

.button-lightGreen {
    background-color: #AAC9BA;
    color: white;

}

.button-darkGreen {
    background-color: #335C67;
    color: white;
}

.button-lightRed {
    background-color: #dbacac;
    color: white;
}

.button-darkRed {
    background-color: #B12506;
    color: white;
}

.button-lightGreen:hover, 
.button-darkGreen:hover, 
.button-lightRed:hover, 
.button-darkRed:hover {
    opacity: 0.8;
}

.disabled {
    opacity: 0.5; 
    pointer-events: none; 
    text-decoration: line-through;
  }

.HistoryDateRangePicker {
    border-radius: 20px !important;
    padding: 10px 20px !important;
    max-width: 80% !important;
    background-color: #EFF0F0 !important;
    color: #3A3A3A;
}

.DesktopHistoryDateRangePicker {
    border-radius: 20px !important;
    padding: 10px 20px !important;
    max-width: 80% !important;
    background-color: white !important;
    color: #3A3A3A;
}

.flatInputField {
    border: 2px solid lightgrey;
    border-radius: 8px;
}

/* left */
/* .daterangepicker .drp-calendar.left {
} */

/* right */
/* #DesktopHistoryDateRangePicker > .daterangepicker .ranges, .daterangepicker .drp-calendar {
    float: none;
} */

.gearIcon {
    font-size: 20px;
    color: #B2B2B2;
    border: 1px solid #B2B2B2;
    border-radius: 8px;
    padding: 2px 7px 0px 7px;
    width: fit-content;
    height: fit-content;
}

.catSummaryContainer {
    background-color: white;
    border-radius: 8px;
    padding: 30px;
}

.catSummaryTitle {
    font-size: 19px;
    font-weight: bold;
}

.momSpendContainer {
    background-color: white;
    border-radius: 8px;
    padding: 30px;
    height: 100%;

}

.momSpendWrapper {
    height: 250px;
}

.legendLabelContainer {}

.legendSquare {
    height: 15px;
    width: 15px !important;
    margin-right: 10px;
    margin-top: 3px;
}

.legendRed {
    color: #FFD0C6;
}

.legendYellow {
    color: #FFDE9F;
}

.legendGreen {
    color: #C9F2E8;
}

.legendLabel {
    color: #6F6F6F;
    font-size: 14px;
}

.alertSubheader {
    color: #3A3A3A;
    font-size: 14px;
    font-weight: bold;
}

.alertDisplay {
    color: #335C67;
    font-size: 18px;
    font-weight: bolder;
    line-height: 22px;
}


.dollarbillimg {
    width: 70px;
    margin-left: -15px;
    margin-top: -26px;
}


.bottom-modal-dialog {
    position: fixed !important;
    top: auto;
    right: auto;
    left: auto;
    bottom: 0;
    width: 100% !important;
    margin: 0 !important;
}

.bottomModal {
    height: 350px;
}


.bottom-modal-dialog>.modal-content {
    border: none !important;
    border-radius: 20px 20px 0px 0px;
}

.modal:has(> .bottom-modal-dialog).fade .modal-dialog {
    transform: translate3d(0, 100vh, 0) !important;
    transition: all 0.3s ease-out !important;
}

.modal:has(> .bottom-modal-dialog).show .modal-dialog {
    transform: translate3d(0, 0, 0) !important;
    transition: all 0.3s ease-out !important;
}

/* need modal-content: broder: none, radius */

.desktopNav {
    width: 250px;
    height: 100%;
    position: fixed;
    /* top: 0; */
    color: #B2B2B2;
    z-index: 2;
}

.desktopNavItem {
    padding: 20px !important;
}

.desktopNavAdd {
    font-size: 1.13rem !important;
}

.contentSpace {
    display: block;
    padding-left: 250px;
    height: 100%;
    width: 100%;
    /* position: fixed; */
    top: 0;
}

.invalid {
    border: 2px solid #f1543b;
}

html,
body,
#root,
.App,
.fullHeight {
    height: 100%;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: #728BB4 !important;
    color: white !important;
    padding: 20px;

}

.navLogo {
    width: 30%;
    margin: auto;
}

.bottomNavAlert {
    /* background-color: #728BB4; */
    background-color: #e4e8f0;
    width: 200px;
    border-radius: 20px;
    margin: auto;

}

.btn-primary-purple {
    background-color: #728BB4 !important;
    color: white !important;
}

.desktopPill {
    background-color: white;
    height: 100px;
    padding: 20px 50px;
    border-radius: 20px;
}

.sidePurple {
    border-left: 15px solid #728BB4;
}

.sideRed {
    border-left: 15px solid #DE8B8B;
}

.solidGreen {
    background-color: #7EC9C4;
}

.solidPink {
    background-color: #DE8B8B;
}

.solidYellow {
    background-color: #F4BA47;
}

.saveRateCircle {
    width: 80%;
    aspect-ratio: 1;
    height: auto;
    border-radius: 200px;
    background-color: #FFCE6F;
    margin: auto;
    border: 10px solid white;
    display: block;
    padding-top: 22%;
    position: relative;
}

.desktopSaveRateDisplay {
    font-size: 3.25rem;
    font-weight: bold;
    line-height: 50px;
}

.desktopSaveContainer {
    padding: 20px !important;
    width: 100%;
    margin-top: -50px;
    padding-top: 80px !important;
}

.desktopToReachGoal {
    border-radius: 15px;
    background-color: rgba(255, 222, 159, 0.47);
    padding: 20px;
    text-align: center;
    margin-top: 40px;
    color: #3A3A3A;
}

.hoverPointer:hover {
    cursor: pointer !important;
}

.ticket {
    max-width: 500px !important;
    min-height: 650px;
    margin: 100px 500px;
    margin-bottom: 20px;
    margin-top: 50px;
    position: relative;
    background: white;
    border-radius: 20px;
    padding: 5px;
    padding-bottom: 50px;
  }
/*   
  .ticket:before,
  .ticket:after {
    content: '';
    display: block;
    position: absolute;
    top: 175px;
    top: var(--dottedLineTop);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    z-index: 2;
    background-color: #f2f2f2;
  } */

  .ticketLine {
    margin-top: 20px;
    margin-left: 40px;
    margin-right: 40px;
    border-top: dashed 3px;
  }
  
  /* .ticket:before {
    border: 5px solid var(--color1);
    border-top-color: transparent;
    border-left-color: transparent;
    transform: rotate(-45deg);
    left: -35px;
  }
  
  .ticket:after {
    border: 5px solid var(--color4);
    border-top-color: transparent;
    border-left-color: transparent;
    transform: rotate(135deg);
    right: -35px;
  } */
  
  .ticketHoles {
    margin-top: -80px;
    margin-bottom: -50px;

  }

  .ticketHoles:before,
  .ticketHoles:after {
    content: '';
    display: inline-block;
    position: relative;
    /* top: 175px; */
    /* top: var(--dottedLineTop); */
    top: -40px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    z-index: 2;
    background-color: #f2f2f2;
  }

  .ticketHoles:before {
    border: 5px solid var(--color1);
    border-top-color: transparent;
    border-left-color: transparent;
    left: -30px;
    top: 55px;
  }
  
  .ticketHoles:after {
    border: 5px solid var(--color4);
    border-top-color: transparent;
    border-left-color: transparent;
    right: -470px;
    margin-top: -10px;
  }



  .ticket-content-wrapper {
    width: 500px;
    height: 100%;
    position: relative;
    background: var(--background);
    border-radius: 15px;
    margin-left: -10px;
  }

  .desktopSaveButton {
    min-height: 50px;
    
    padding-top: 10px;
    font-size: 20px;
    border-radius: 8px;
    background-color: #7EC9C4;
    color: white;
    text-align: center
  }

  .desktopSaveButton:hover {
    cursor: pointer;
    background-color: #5fada8;
  }
  
  
  .desktopSaveButtonDisabled {
    min-height: 50px;
    
    padding-top: 10px;
    font-size: 20px;
    border-radius: 8px;
    background-color: #7EC9C4;
    color: white;
    text-align: center;
    opacity: 0.5;
  }


  .tourButton {
    position: fixed;
    bottom: 5px;
    right: 5px;
    height: 20px;
    color: #d6d6d6 !important;
    --bs-btn-padding-y: .25rem !important; 
    --bs-btn-padding-x: .5rem !important; 
    --bs-btn-font-size: .75rem !important;
  }

  .tourButton:hover {
    cursor: pointer;
    color: #b1b0b0 !important;
    --bs-btn-padding-y: .25rem !important; 
    --bs-btn-padding-x: .5rem !important; 
    --bs-btn-font-size: .75rem !important;
  }

  .fullBG {
    height: fit-content;
  }

  .shepherd-button-primary:hover {
    background-color: #2568af !important;

  }

  .shepherd-element-bottom {
    margin-top: 20px !important;
  }

  .shepherd-element-top {
    margin-top: -20px !important;
  }

  .shepherd-element-left {
    margin-left: -20px !important;
  }